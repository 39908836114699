import Language from "../Language";
import EsLocale from "./EsLocale";
import Locale from "./Locale";
import EnLocale from "./EnLocale";

class LocaleFactory {
    private static factoryMap: Map<Language, Locale> = new Map<Language, Locale>([
        [Language.ES, new EsLocale()],
        [Language.EN, new EnLocale()]
    ]);

    static getLocale(language: Language): Locale {
        return this.factoryMap.get(language) || new EsLocale();
    }
}

export default LocaleFactory;