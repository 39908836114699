import Locale from "./Locale";
import words from "./translations/es/index";

class EsLocale extends Locale {

    translate(text: string): string {
        return words[text] || text;
    }
}

export default EsLocale;