class Request {
    public readonly url: string;
    public readonly data: any;

    constructor(url: string, data?: any ) {
        this.url = url;
        this.data = data;
    }
}

export default Request;