class Response<T> {
    public readonly success: boolean;
    public readonly data?: T;
    public readonly error?: string;
    public readonly status?: number;

    constructor(success: boolean, data?: T, error?: string, status?: number) {
        this.data = data;
        this.success = success;
        this.error = error;
        this.status = status;
    }
}

export default Response;