const values = {
    "INVALID_EMAIL_OR_PASSWORD": "Invalid email or password",
    "EMAIL_REQUIRED": "The email is required",
    "INVALID_EMAIL": "Invalid email",
    "CONFIRM_PASSWORD_ERROR": "Password and confirm password most be equals",

    "EMAIL_ALREADY_EXIST": "There is a seller already created with this email",
    "EMAIL_ALREADY_EXIST_2": "Not possible to create a seller with this email",
    "NAME_REQUIRED": "The name is required",
}

export default values;