import Language from "../i18n/Language";
import {observable} from "mobx";
import Locale from "../i18n/locale/Locale";
import LocaleFactory from "../i18n/locale/LocaleFactory";

class I18nStore {
    @observable
    private language: Language;
    private locale: Locale;

    constructor(language: Language) {
        this.language = language;
        this.locale = I18nStore.loadLocale(language);
    }

    getLanguage(): Language {
        return this.language;
    }

    setLanguage(language: Language) {
        this.language = language;
        this.locale = I18nStore.loadLocale(language);
    }

    translate(text?: string): string {
        if (!text) return "";
        return this.locale.translate(text);
    }

    private static loadLocale(language: Language): Locale {
        return LocaleFactory.getLocale(language);
    }
}

export default I18nStore;