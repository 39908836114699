import Locale from "./Locale";
import words from "./translations/en/index";

class EnLocale extends Locale {

    translate(text: string): string {
        return words[text] || text;
    }
}

export default EnLocale;